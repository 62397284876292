import React from "react";

const CustomerSatisfactionCard = ({ item }) => {
  return (
    <div className="customer_row p-4 rounded-8">
      <h3 className="mb-3 fs-xl fw-bold">{item.title}</h3>
      <ul className="list_style_none d-flex gap-2">
        <li className="d-flex gap-2 align-items-start w-100">
          <span className="material-symbols-outlined icon-fill text-green">
            check_circle
          </span>
          <p>{item.description} .</p>
        </li>
      </ul>
    </div>
  );
};

export default CustomerSatisfactionCard;
