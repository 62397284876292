import { Link } from "react-router-dom";
import { assetImages } from "Constants/Images";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer_sec gredient-bg pd-50">
      <div className="container position-relative">
        {/* <!-- ========== Start Footer link Section ========== --> */}
        <div className="ftr_outer">
          <div className="row">
            {/* <!-- ========== Start footer logo Section ========== --> */}
            <div className="col-lg-3">
              <div className="ftr_logo mb-5">
                <Link to="/" className="ftr_logo d-inline-block">
                  <img src={assetImages.FooterLogoSvg} alt="" />
                </Link>
              </div>
            </div>
            {/* <!-- ========== End footer logo Section ========== --> */}

            {/* <!-- ========== Start main links Section ========== --> */}
            <div className="col-lg-3 col-md-4 col-6">
              <div className="ftr_item">
                <h3 className="fs-md fw-bold mb-4">
                  {t("Main")} {t("Links")}
                </h3>
                <ul className="list_style_none ftr_links">
                  <li className="mb-2">
                    <Link to="#" className="fs-sm text-primary fw-medium">
                      {t("About us")}
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link to="#" className="fs-sm text-primary fw-medium">
                      {t("Services")}
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link to="#" className="fs-sm text-primary fw-medium">
                      {t("Projects")}
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link to="#" className="fs-sm text-primary fw-medium">
                      {t("Contact")}
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link to="#" className="fs-sm text-primary fw-medium">
                      {t("News")}
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link to="#" className="fs-sm text-primary fw-medium">
                      {t("Events")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- ========== End main links Section ========== --> */}

            {/* <!-- ========== Start contact now Section ========== --> */}
            <div className="col-lg-3 col-md-4 col-6">
              <div className="ftr_item">
                <h3 className="fs-md fw-bold mb-4">
                  {t("Contact")} {t("Info")}
                </h3>
                <div className="contact_info_outer">
                  <div className="contact_info_row d-flex gap-2 mb-3">
                    <span className="material-symbols-outlined icon-fill info-icon fs-lg">
                      location_on
                    </span>
                    <p className="fw-medium">24/7 Mandisa, Madrid, Spain</p>
                  </div>
                  <div className="contact_info_row d-flex gap-2 mb-3">
                    <span className="material-symbols-outlined icon-fill info-icon fs-lg">
                      mail
                    </span>
                    <p className="fw-medium">
                      <Link
                        to="#"
                        className="fs-sm text-primary d-inline-block"
                      >
                        Support@musamerda.com
                      </Link>
                    </p>
                  </div>
                  <div className="contact_info_row d-flex gap-2">
                    <span className="material-symbols-outlined icon-fill info-icon fs-lg">
                      phone_in_talk
                    </span>
                    <p className="fw-medium">
                      <span className="d-block mb-2 text-primary">
                        <Link to="tel:+41 8932568901" className="text-primary">
                          +41 8932568901
                        </Link>
                      </span>
                      <span className="d-block text-primary">
                        <Link to="tel:+41 8932568901" className="text-primary">
                          +41 8932568901
                        </Link>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- ========== End contact now Section ========== --> */}

            {/* <!-- ========== Start social media Section ========== --> */}
            <div className="col-lg-3 col-md-4 col-12">
              <div className="ftr_links_outer">
                <div className="ftr_item mb-5">
                  <h3 className="fs-md fw-bold mb-4">{t("Social Media")}</h3>
                  <ul className="list_style_none ftr_links">
                    <li className="mb-2">
                      <Link
                        to="#"
                        className="fs-sm text-primary fw-medium d-flex align-items-center gap-2"
                      >
                        <i className="d-inline-block">
                          <img src={assetImages.FacebookSvg} alt="" />
                        </i>
                        <span className="d-inline-block">Facebook</span>
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to="#"
                        className="fs-sm text-primary fw-medium d-flex align-items-center gap-2"
                      >
                        <i className="d-inline-block">
                          <img src={assetImages.InstagramSvg} alt="" />
                        </i>
                        <span className="d-inline-block">Instagram</span>
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to="#"
                        className="fs-sm text-primary fw-medium d-flex align-items-center gap-2"
                      >
                        <i className="d-inline-block">
                          <img src={assetImages.TwitterSvg} alt="" />
                        </i>
                        <span className="d-inline-block">Twitter</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- ========== End social media Section ========== --> */}
          </div>
        </div>
        {/* <!-- ========== End Footer link Section ========== --> */}

        {/* <!-- ========== Start copyright Section ========== --> */}
        <div className="copyright">
          <p>{t("Copyright")} © 2023 Musamerda</p>
        </div>
        {/* <!-- ========== End copyright Section ========== --> */}
      </div>
    </footer>
  );
};

export default Footer;
