/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ecosystemSlug } from "Config/Config";

/*------ import url and method ------*/
import * as url from "Helpers/UrlHelper/UrlHelper";
import { getData } from "Utils/Gateway";
import { assetImages } from "Constants/Images";

const PriceBooking = ({ setpricePlanId }) => {
  const { t } = useTranslation();

  const [planList, setPlanList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //function for get all tags
  const getAllPlans = async () => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PLANS +
        `?ecosystemslug=${ecosystemSlug}`;

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        if (response.data.length > 0) {
          const sortedPlansByPrice = response.data.sort(
            (a, b) => a.price - b.price
          );
          setPlanList(sortedPlansByPrice);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  return (
    <section className="pricing_area pd_85 bg-dark">
      <div className="container">
        {/* <!-- ========== Start price booking heading Section ========== --> */}
        <div className="page_hdng text-center mb-5">
          <h2 className="fw-bold mb-4 text-white">
            {t("We have something")} <br /> {t("in your budget")}
          </h2>
          <p className="fs-md text-white">
            {t(
              "Get the best offerings form a team that dedicated for you and yours."
            )}
          </p>
          <div className="bullet_border text-center">
            <span className="d-inline-block position-relative">
              <img src={assetImages.BulletSvg} alt="" />
            </span>
          </div>
        </div>

        {/* <!-- ========== End price booking heading Section ========== --> */}

        {/* <!-- ========== Start price booking list Section ========== --> */}
        <div className="pricing_outer">
          <div className="row">
            {planList.map((plan, index) => {
              return (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div className="pricing_item bg-light-ylw rounded-16 h-100 p-4">
                    {/* <!-- ========== Start plan title and price Section ========== --> */}
                    <div className="pricing_top mb-4">
                      <h4 className="fs-sm mb-3 text-primary fw-bold d-flex align-items-center gap-2">
                        {plan.title}
                        <span className="gredient-bg px-3 rounded-90 py-2">
                          {plan.persons} {t("Person")}
                        </span>
                      </h4>
                      <h2 className="fw-bold mb-2">
                        €{plan.price}{" "}
                        <sub className="fs-sm">/{t("month")} </sub>
                      </h2>
                      <p className="fs-md">{plan.description.slice(0, 100)}</p>
                    </div>
                    {/* <!-- ========== End plan title and price Section ========== --> */}

                    {/* <!-- ========== Start plan kpis list Section ========== --> */}
                    <div className="pricing_list mb-4">
                      <h4 className="text-uppercase mb-4 text-uppercase fs-sm fw-bold">
                        {t("What's included")}:
                      </h4>
                      <ul className="list_style_none">
                        {plan.kpis.map((kpi, index2) => {
                          return (
                            <li className="d-flex gap-2 mb-3" key={index2}>
                              <span
                                className={`material-symbols-outlined icon-fill fs-lg text-blue`}
                              >
                                check_circle
                              </span>
                              <p>{kpi}</p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {/* <!-- ========== End plan kpis list Section ========== --> */}

                    {/* <!-- ========== Start subscribe button Section ========== --> */}
                    <div className="pricing_bttm">
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#subscribe_modal"
                        className="btn w-100"
                        onClick={() => {
                          setpricePlanId(plan._id);
                        }}
                      >
                        {t("Subscribe")}
                      </Link>
                    </div>
                    {/* <!-- ========== End subscribe button Section ========== --> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <!-- ========== End price booking list Section ========== --> */}
      </div>
    </section>
  );
};

export default PriceBooking;
