/* eslint-disable */
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ecosystemSlug, owneremail } from "Config/Config";

/*------ import url and method ------*/
import * as url from "Helpers/UrlHelper/UrlHelper";
import { postData } from "Utils/Gateway";

const BookingModal = ({ sourceName, setSourceName }) => {
  const { t } = useTranslation();

  const fullUrl = window.location.href; // get url from web browser

  //form requirements
  const [name, setname] = useState("");
  const [surName, setsurName] = useState("");
  const [email, setemail] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [message, setmessage] = useState("");
  const [requiredWarning, setRequiredWarning] = useState(false);
  const [isSending, setisSending] = useState(false);

  //function submit contact data
  const submitMessageHandler = async () => {
    //checking empty validation
    if (name == "" || email == "" || message == "") {
      setRequiredWarning(true);
    } else {
      setRequiredWarning(false);
      try {
        setisSending(true);

        let contactData = {
          name,
          surname: surName,
          email,
          company: companyName,
          customercomment: message,
          source: sourceName || "",
          sourceurl: fullUrl || "",
          leadownermail: owneremail,
        };

        console.log(contactData);

        let requestUrl =
          url.API_BASE_URL +
          url.API_SAVE_GUEST_LEAD +
          `?ecosystemslug=${ecosystemSlug}`;

        const response = await postData(requestUrl, contactData);
        setisSending(false);
        // console.log(response);

        if (response.status) {
          resetHandler();
          let myModal = document.querySelector("#booking_modal");
          let modal = bootstrap.Modal.getInstance(myModal);
          modal.hide();

          //show register modal
          let showModal = new bootstrap.Modal(
            document.getElementById("bookin_success_modal")
          );
          showModal.show();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //reset function
  const resetHandler = () => {
    setname("");
    setsurName("");
    setemail("");
    setcompanyName("");
    setmessage("");
    setSourceName("");
    setRequiredWarning(false);
  };

  return (
    <div className="booking_modal">
      <div
        className="modal fade show"
        data-bs-backdrop="static"
        id="booking_modal"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content p-4">
            <div className="modal-header justify-content-between position-relative align-items-start">
              <div className="signin_hdng">
                <h3 className="text-primary mb-2 fw-bold">{t("Subscribe")}</h3>
                <h5 className="fs-md"></h5>
              </div>
              <button
                type="button"
                className="close d-flex align-items-center justify-content-center rounded-circle gredient-bg text-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetHandler}
              >
                <i className="material-symbols-outlined">close</i>
              </button>
            </div>
            <div className="modal-body">
              <div className="booking_box">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="booking_fillOuter">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label className="text-uppercase fw-bold mb-2 fs-sm">
                            {t("Name")}
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-8"
                            placeholder={t("Enter Name (*required)")}
                            value={name}
                            onChange={(e) => {
                              setRequiredWarning(false);
                              setname(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label className="text-uppercase fw-bold mb-2 fs-sm">
                            {t("Surname")}
                          </label>
                          <input
                            type="text"
                            className="form-control rounded-8"
                            placeholder={t("Enter Surname")}
                            value={surName}
                            onChange={(e) => setsurName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="form-group">
                          <label className="text-uppercase fw-bold mb-2 fs-sm">
                            {t("Email")}
                          </label>
                          <input
                            type="email"
                            className="form-control rounded-8"
                            placeholder={t("Enter Email (*required)")}
                            value={email}
                            onChange={(e) => {
                              setRequiredWarning(false);
                              setemail(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="form-group description-fill">
                          <label className="text-uppercase fw-bold mb-2 fs-sm">
                            {t("Message")}
                          </label>
                          <textarea
                            className="form-control rounded-8"
                            placeholder={t("Enter message")}
                            value={message}
                            onChange={(e) => {
                              setmessage(e.target.value);
                              setRequiredWarning(false);
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="create_chk_outer mb-3">
                    <div className="form-check p-0 d-flex  gap-2 m-0 cursor-pointer  mb-3">
                      <input
                        className="form-check-input d-block m-0"
                        type="checkbox"
                        id="overdue2"
                      />
                      <label
                        className="form-check-label d-block text-gray cursor-pointer"
                        htmlFor="overdue"
                      >
                        {t("Agree with the")}{" "}
                        <Link to="#" className="text-pink">
                          {t("Legal Notice")}
                        </Link>{" "}
                        &{" "}
                        <Link to="#" className="text-pink">
                          {t("Privacy Policy")}
                        </Link>
                      </label>
                    </div>
                  </div>
                  {requiredWarning && (
                    <p className="text-danger fw-bold">
                      * {t("Please fill up the required fields")}
                    </p>
                  )}
                  <div className="signup_btns_outer d-flex align-items-center justify-content-between mt-4">
                    <div className="mail_info_left d-flex align-items-center gap-2">
                      <div className="mail_icon rounded-circle gredient-bg overflow-hidden d-flex align-items-center justify-content-center">
                        <span className="material-symbols-outlined text-primary fs-lg">
                          mail
                        </span>
                      </div>
                      <div className="mail_text">
                        <h5 className="mb-2">
                          {t("For any other quarry contact us")}
                        </h5>
                        <h3 className="fs-sm">Support@musamerdamail.com </h3>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={submitMessageHandler}
                      style={{ cursor: isSending ? "not-allowed" : "pointer" }}
                      disabled={isSending ? true : false}
                    >
                      {t("Subscribe")}
                      {isSending && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingModal;
