import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import SildeItemCard from "./SlideItemCard/SildeItemCard";
import { assetImages } from "Constants/Images";

//custom arrow button for react carousel outside
const BannerButtonGroup = ({ next, previous }) => {
  return (
    <div className="inbtns bnr_arws">
      <button className={previous ? "" : "disable"} onClick={previous}></button>
      <button onClick={previous} className="prev slick-arrow">
        <span className="material-symbols-outlined">arrow_back</span>
      </button>
      <button onClick={next} className="next slick-arrow">
        <span className="material-symbols-outlined">arrow_forward</span>
      </button>
    </div>
  );
};

const Banner = ({ setSourceName }) => {
  //array of background slide images. it will replace by API
  const slideImages = [
    {
      id: 1,
      backgroundImage: assetImages.bannerSlide1,
    },
    {
      id: 2,
      backgroundImage: assetImages.bannerSlide2,
    },
    {
      id: 3,
      backgroundImage: assetImages.bannerSlide3,
    },
  ];

  //configuration for react carousel
  const bannerResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="banner_sec position-relative bg-light-ylw">
      <div className="banner_slider">
        <Carousel
          infinite
          responsive={bannerResponsive}
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<BannerButtonGroup />}
        >
          {/* <!-- ========== Start map of slide images Section ========== --> */}
          {slideImages.map((slideItem, index) => {
            return (
              <SildeItemCard
                key={index}
                slideItem={slideItem}
                setSourceName={setSourceName}
              />
            );
          })}
          {/* <!-- ========== End map of slide images Section ========== --> */}
        </Carousel>
      </div>
    </section>
  );
};

export default Banner;
