import React from "react";

const ArtistSlideCard = ({ slideItem }) => {
  return (
    <div className="artist_item">
      <div className="artist_innr p-3 gredient-bg rounded-24">
        <div className="artist_pic overflow-hidden rounded-16 mb-4">
          <img
            className="h-100 w-100 object-fit-cover"
            src={slideItem.imageUrl}
            alt=""
          />
        </div>
        <div className="artist_info px-2">
          <h3 className="fs-lg mb-1 fw-bold">{slideItem.artistName}</h3>
          <h5 className="fs-lg fw-regular">{slideItem.role}</h5>
        </div>
      </div>
    </div>
  );
};

export default ArtistSlideCard;
