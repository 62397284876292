import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { assetImages } from "Constants/Images";

const BookingContact = ({ setSourceName }) => {
  const { t } = useTranslation();
  return (
    <section className="contact_sec bg-dark pd_85">
      <div className="container">
        <div className="page_hdng mb-4 text-center position-relative">
          {/* <!-- ========== Start booking heading Section ========== --> */}
          <h2 className="fw-bold text-white mb-4">
            {t("Join Today")}
            <span className="d-inline-block fw-extra-light">
              {t("and Embark on an")}
            </span>{" "}
            <br /> {t("Unmatched Theatrical")} <br /> {t("Adventure")}
          </h2>
          {/* <!-- ========== End booking heading Section ========== --> */}

          {/* <!-- ========== Start booking sub heading Section ========== --> */}
          <p className="text-white fs-md">
            {t("Subscribe now and let the magic of theater envelop you")}!
          </p>
          {/* <!-- ========== End booking sub heading Section ========== --> */}

          {/* <!-- ========== Start booking button Section ========== --> */}
          <div className="arws_btn position-relative d-inline-block mt-5">
            <Link
              to="#"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#booking_modal"
              onClick={() => {
                setSourceName("join_theater");
              }}
            >
              {t("Book Now For The Events")}
            </Link>
            <span className="indigate_arw d-inline-block">
              <img src={assetImages.btnArrowSvg} alt="" />
            </span>
          </div>
          {/* <!-- ========== End booking button Section ========== --> */}
        </div>
      </div>
    </section>
  );
};

export default BookingContact;
