import mainLogo from "Assets/images/logo.svg";
import languageLogo1 from "Assets/images/language-logo1.svg";
import languageLogo2 from "Assets/images/language-logo2.svg";
import bannerSlide1 from "Assets/images/macho-grita-1copy.jpg";
import bannerSlide2 from "Assets/images/roca-negra-1.jpg";
import bannerSlide3 from "Assets/images/las-tragicas-payasas-de-shakespeare-4.jpg";
import btnArrow from "Assets/images/btn-arw.svg";
import UnionSvg from "Assets/images/union.svg";
import MembershipPic1 from "Assets/images/membership-pic1.jpg";
import MembershipPic2 from "Assets/images/membership-pic2.jpg";
import MembershipPic3 from "Assets/images/membership-pic3.jpg";
import MembershipPic4 from "Assets/images/membership-pic4.jpg";
import MembershipPic5 from "Assets/images/membership-pic5.jpg";
import BulletSvg from "Assets/images/bullet.svg";
import Play1 from "Assets/images/plays1.jpg";
import Play2 from "Assets/images/plays2.jpg";
import Play3 from "Assets/images/plays3.jpg";
import Play4 from "Assets/images/plays4.jpg";
import DiscoverBg from "Assets/images/discover-bg.jpg";
import Artist1 from "Assets/images/artist1.jpg";
import Artist2 from "Assets/images/artist2.jpg";
import Artist3 from "Assets/images/artist3.jpg";
import Artist4 from "Assets/images/artist4.jpg";
import EventPic from "Assets/images/event-pic.jpg";
import EventThumb1 from "Assets/images/event_thumb1.jpg";
import EventThumb2 from "Assets/images/event_thumb2.jpg";
import EventThumb3 from "Assets/images/event_thumb3.jpg";
import TestiAuthor1 from "Assets/images/testi-autor1.jpg";
import TestiAuthor2 from "Assets/images/testi-autor2.jpg";
import TestiAuthor3 from "Assets/images/testi-autor3.jpg";
import TestiAuthor4 from "Assets/images/testi-autor4.jpg";
import FooterLogoSvg from "Assets/images/ftr-logo.svg";
import FacebookSvg from "Assets/images/facebook.svg";
import InstagramSvg from "Assets/images/instagram.svg";
import TwitterSvg from "Assets/images/twitter.svg";
import CongratsIcon from "Assets/images/congrats.png";
import btnArrowSvg from "Assets/images/btn-arw.svg";
// import MembershipPicSome from "Assets/images/";
// import MembershipPicSome from "Assets/images/";
// import MembershipPicSome from "Assets/images/";
// import MembershipPicSome from "Assets/images/";
// import MembershipPicSome from "Assets/images/";
// import MembershipPicSome from "Assets/images/";
// import MembershipPicSome from "Assets/images/";
// import MembershipPicSome from "Assets/images/";
// import MembershipPicSome from "Assets/images/";
// import MembershipPicSome from "Assets/images/";

export const assetImages = {
  mainLogo,
  languageLogo1,
  languageLogo2,
  bannerSlide1,
  bannerSlide2,
  bannerSlide3,
  btnArrow,
  UnionSvg,
  MembershipPic1,
  MembershipPic2,
  MembershipPic3,
  MembershipPic4,
  MembershipPic5,
  BulletSvg,
  Play1,
  Play2,
  Play3,
  Play4,
  DiscoverBg,
  Artist1,
  Artist2,
  Artist3,
  Artist4,
  EventPic,
  EventThumb1,
  EventThumb2,
  EventThumb3,
  TestiAuthor1,
  TestiAuthor2,
  TestiAuthor3,
  TestiAuthor4,
  FooterLogoSvg,
  FacebookSvg,
  InstagramSvg,
  TwitterSvg,
  CongratsIcon,
  btnArrowSvg,
};
