import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomerSatisfactionCard from "./CustomerSatisfactionCard/CustomerSatisfactionCard";

const CustomerSatisfaction = () => {
  const { t } = useTranslation();

  //Array of customer staisfactions
  const customerSatisFactionList = [
    {
      id: 1,
      title: t("Monthly Tickets and Ticket Discounts"),
      description: t(
        "Instead of running out of tickets due to lack of planning, you will have your ticket reserved until the last minute, and at a discount!"
      ),
    },
    {
      id: 2,
      title: t("More immersive and complete experiences"),
      description: t(
        "When you are a theater lover, you are not satisfied just by watching the show. With The Members Club, you will have access to meet the cast and creative team, as well as private content and backstage access."
      ),
    },
    {
      id: 3,
      title: t("Personalized advice"),
      description: t(
        "Instead of randomly choosing the next work you will see, you will have access to experts who recommend you based on your tastes, in addition to suggestions from the platform based on the works you have already seen and the social network, where you can find fans with similar tastes to yours. yours."
      ),
    },
    {
      id: 4,
      title: t("Crowdfunding for Investing in New Productions"),
      description: t(
        "If you have been a theater fan for some time, it is normal that you have had the desire to be part of the plays in a more active way. With the Crowdfunding tool you can add value and actively promote new works to see the light"
      ),
    },
    {
      id: 5,
      title: t("Training"),
      description: t(
        "Participate in exclusive courses where you will learn about acting, directing and any theatrical field that catches your attention"
      ),
    },
  ];

  return (
    <section className="customer_satisfication_sec pd_85 bg-light-ylw">
      <div className="container">
        {/* <!-- ========== Start heading Section ========== --> */}
        <div className="page_hdng text-center mb-5">
          <h2 className="fw-extra-light text-primary">
            {t("We Converts Customers")}
            <span className="fw-bold d-block">
              {t("Pain into Customer Satisfaction")} ?
            </span>{" "}
          </h2>
          <div className="bullet_border text-center">
            <span className="d-inline-block position-relative">
              <img src="images/bullet.svg" alt="" />
            </span>
          </div>
        </div>
        {/* <!-- ========== End heading Section ========== --> */}

        {/* <!-- ========== Start customer satisfaction Section ========== --> */}
        <div className="customer_pnl">
          {customerSatisFactionList.map((item, index) => {
            return <CustomerSatisfactionCard key={index} item={item} />;
          })}
        </div>
        {/* <!-- ========== End customer satisfaction Section ========== --> */}

        {/* <!-- ========== Start load more button Section ========== --> */}
        <div className="load_more_btn mt-5 d-flex justify-content-center">
          <Link to="#" className="btn btn-primary">
            {t("Load More")}
          </Link>
        </div>
        {/* <!-- ========== End load more button Section ========== --> */}
      </div>
    </section>
  );
};

export default CustomerSatisfaction;
