import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import ExclusiveModuleCard from "./ExclusiveModuleCard/ExclusiveModuleCard";

//custom arrow button for react carousel outside
const ModuleButtonGroup = ({ next, previous }) => {
  return (
    <div className="inbtns exclusive_arws d-flex align-items-center gap-3">
      <button className={previous ? "" : "disable"} onClick={previous}></button>
      <button onClick={previous} className="prev slick-arrow">
        <span className="material-symbols-outlined">arrow_back</span>
      </button>
      <button onClick={next} className="next slick-arrow">
        <span className="material-symbols-outlined">arrow_forward</span>
      </button>
    </div>
  );
};

const ExclusiveModule = () => {
  const { t } = useTranslation();

  //array of exclusive items
  const exclusiveItems = [
    {
      id: 1,
      icon: "manage_accounts",
      title: t("Custom Profile"),
      titleWithBr: t("Management"),
      description: t(
        "Customize your profile with your theatrical preferences and keep track of your attended events"
      ),
    },
    {
      id: 2,
      icon: "confirmation_number",
      title: t("Stress-Free"),
      titleWithBr: t("Ticket Booking"),
      description: t(
        "Book your tickets online quickly and easily, with exclusive access to the best locations."
      ),
    },
    {
      id: 3,
      icon: "calendar_month",
      title: t("Programming"),
      titleWithBr: t("and Events"),
      description: t(
        "Stay up to date with the latest theater productions,show schedules and special events"
      ),
    },
    {
      id: 4,
      icon: "manage_accounts",
      title: t("Custom Profile"),
      titleWithBr: t("Management"),
      description: t(
        "Customize your profile with your theatrical preferences and keep track of your attended events"
      ),
    },
  ];

  //react carousel configuration
  const sliderResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="exclusive_area  bg-light-ylw overflow-hidden">
      <div className="container">
        <div className="exclusive_outer position-relative">
          <div className="row">
            {/* <!-- ========== Start exclusivemodule heading Section ========== --> */}
            <div className="col-lg-3">
              <div className="exclusive_lftpnl">
                <div className="page_hdng pb-3 mb-4 position-relative">
                  <h2 className="fw-extra-light text-primary">
                    {t("Our")}
                    <span className="fw-bold d-block">
                      {t("Exclusive Modules")}
                    </span>{" "}
                  </h2>
                </div>
              </div>
            </div>
            {/* <!-- ========== End exclusivemodule heading Section ========== --> */}

            {/* <!-- ========== Start exclusive module carousel  Section ========== --> */}
            <div className="col-lg-9">
              <div className="exclusive_slider">
                <Carousel
                  infinite
                  responsive={sliderResponsive}
                  arrows={false}
                  renderButtonGroupOutside={true}
                  customButtonGroup={<ModuleButtonGroup />}
                >
                  {exclusiveItems.map((slideItem, index) => {
                    return (
                      <ExclusiveModuleCard key={index} slideItem={slideItem} />
                    );
                  })}
                </Carousel>
              </div>
            </div>
            {/* <!-- ========== End exclusive module carousel  Section ========== --> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExclusiveModule;
