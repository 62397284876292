import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { assetImages } from "Constants/Images";
import EventCard from "./EventCard/EventCard";

const UpcomingEvents = ({ setSourceName }) => {
  const { t } = useTranslation();

  //Array of upcoming event list.This will replace by API.
  const upcomingEventList = [
    {
      id: 1,
      eventThumb: assetImages.EventThumb1,
      eventTitle: "MÁS TRANQUILO",
      eventTime: "THU, 20 JUL- 7:00 AM",
    },
    {
      id: 2,
      eventThumb: assetImages.EventThumb2,
      eventTitle: "DOS TRONOS, DOS REINAS",
      eventTime: "THU, 20 JUL- 7:00 AM",
    },
    {
      id: 3,
      eventThumb: assetImages.EventThumb3,
      eventTitle: "UN SECRETO A VOCES",
      eventTime: "THU, 20 JUL- 7:00 AM",
    },
  ];

  return (
    <section className="event_sec bg-dark">
      <div className="container">
        <div className="row align-items-center">
          {/* <!-- ========== Start event leftside image Section ========== --> */}
          <div className="col-lg-6">
            <div className="evnt_leftpnl">
              <img src={assetImages.EventPic} alt="" />
            </div>
          </div>
          {/* <!-- ========== End event leftside image Section ========== --> */}

          {/* <!-- ========== Start upcoming event and booking Section ========== --> */}
          <div className="col-lg-6">
            <div className="evnt_rightpnl">
              {/* <!-- ========== Start upcoming event heading Section ========== --> */}
              <div className="page_hdng mb-3">
                <h5 className="fs-lg text-white mb-1 text-uppercase">
                  {t("Upcoming Events")}
                </h5>
                <h2 className="fw-extra-light text-white">
                  <span className="fw-bold d-block">
                    {t("This Week's Events")}
                  </span>
                </h2>
              </div>
              {/* <!-- ========== End upcoming event heading Section ========== --> */}

              {/* <!-- ========== Start upcoming event list Section ========== --> */}
              <div className="event_outer">
                {upcomingEventList.map((eventData, index) => {
                  return <EventCard key={index} cardItem={eventData} />;
                })}
              </div>
              {/* <!-- ========== End upcoming event list Section ========== --> */}

              {/* <!-- ========== Start booking button Section ========== --> */}
              <div className="book_btn mt-5">
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#booking_modal"
                  onClick={() => {
                    setSourceName("book_upcoming_events	");
                  }}
                >
                  {t("Book Now For The Events")}
                </Link>
              </div>
              {/* <!-- ========== End booking button Section ========== --> */}
            </div>
          </div>
          {/* <!-- ========== End upcoming event and booking Section ========== --> */}
        </div>
      </div>
    </section>
  );
};

export default UpcomingEvents;
