import { useTranslation } from "react-i18next";
import { assetImages } from "Constants/Images";
import BenifitCardItem from "./BenefitCard/BenifitCardItem";

const MemberShipBenefits = ({ setSourceName }) => {
  const { t } = useTranslation();

  //array of benfit classes , images and texts
  const benefitData = [
    {
      id: 1,
      colClassName: "col-lg-7",
      textClass: "text-white",
      descriptionClass: "text-white",
      backgroundImage: assetImages.MembershipPic1,
      title: t("Priority Access"),
      description: t(
        "Be the first to get tickets for the works you want to see"
      ),
      showBookingButton: false,
    },
    {
      id: 2,
      colClassName: "col-lg-5",
      textClass: "text-primary",
      descriptionClass: "text-primary",
      backgroundImage: assetImages.MembershipPic2,
      title: t("VIP Invitations"),
      description: t(
        "Enjoy special events like opening nights, cast meet-and-greets, and exclusive backstage tours."
      ),
      showBookingButton: true,
    },
    {
      id: 3,
      colClassName: "col-lg-4",
      textClass: "text-white",
      descriptionClass: "text-white",
      backgroundImage: assetImages.MembershipPic3,
      title: t("Discounts and Offers"),
      description: t(
        "Reserve your tickets before the general public and ensure the best seats at each performance"
      ),
      showBookingButton: false,
    },
    {
      id: 4,
      colClassName: "col-lg-4",
      textClass: "text-white",
      descriptionClass: "text-white",
      backgroundImage: assetImages.MembershipPic4,
      title: t("Exclusive Content"),
      description: t(
        "Access interviews with actors, behind-the-scenes content, and more, not available to the general public."
      ),
      showBookingButton: false,
    },
    {
      id: 5,
      colClassName: "col-lg-4",
      textClass: "text-white",
      descriptionClass: "text-white",
      backgroundImage: assetImages.MembershipPic5,
      title: t("Theater Community"),
      description: t(
        "Connect with other theater fans, share experiences and make new friends who share your passion."
      ),
      showBookingButton: false,
    },
  ];

  return (
    <section className="benefits_membership_area pd-50 bg-light-ylw">
      <div className="container">
        {/* <!-- ========== Start mebership benefit heading Section ========== --> */}
        <div className="page_hdng text-center mb-5">
          <span className="d-block mb-2">
            <img src={assetImages.UnionSvg} alt="" />
          </span>
          <h2 className="fw-extra-light">
            {t("All Exclusive")}
            <span className="fw-bold d-block">
              {t("Membership Benefits")}
            </span>{" "}
          </h2>
          <div className="bullet_border text-center">
            <span className="d-inline-block position-relative">
              <img src="images/bullet.svg" alt="" />
            </span>
          </div>
        </div>
        {/* <!-- ========== End mebership benefit heading Section ========== --> */}

        {/* <!-- ========== Start benefits and images Section ========== --> */}
        <div className="benefits_outer">
          <div className="row">
            {benefitData.map((benefitItem, index) => {
              return (
                <BenifitCardItem
                  key={index}
                  t={t}
                  benefitItem={benefitItem}
                  setSourceName={setSourceName}
                />
              );
            })}
          </div>
        </div>
        {/* <!-- ========== End benefits and images Section ========== --> */}
      </div>
    </section>
  );
};

export default MemberShipBenefits;
