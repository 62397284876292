import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FaqCard from "./FaqCard/FaqCard";

const Faq = () => {
  const { t } = useTranslation();

  //Array of FAQ
  const faqList = [
    {
      id: 1,
      question: t("What does the monthly subscription include"),
      answer: t(
        "Our monthly subscription grants you access to a variety of live theater performances, exclusive events, and special discounts on tickets"
      ),
    },
    {
      id: 2,
      question: t("Can I cancel my subscription at any time"),
      answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit,",
    },
    {
      id: 3,
      question: t(
        "How do I access the live theater performances with my subscription?"
      ),
      answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit,",
    },
    {
      id: 4,
      question: t("Are there any additional fees for using the subscription?"),
      answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit,",
    },
    {
      id: 5,
      question: t("Can I upgrade or downgrade my subscription plan?"),
      answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit,",
    },
    {
      id: 6,
      question: t(
        "Do you offer refunds for unused portions of mysubscription?"
      ),
      answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit,",
    },
    {
      id: 7,
      question: t("Do you offer a free trial for the subscription?"),
      answer: "Lorem ipsum dolor sit amet, consectetur adipisicing elit,",
    },
  ];

  return (
    <section className="faq_sec bg-light-ylw pd_85">
      <div className="container">
        <div className="faq_outer">
          <div className="row">
            {/* <!-- ========== Start Faq heading Section ========== --> */}
            <div className="col-lg-3">
              <div className="faq_hdng_pnl">
                <div className="page_hdng pb-3 mb-4 position-relative">
                  <h2 className="fw-extra-light">
                    {t("Frequently Asked")}
                    <span className="d-block fw-bold">{t("Question")}</span>
                  </h2>
                </div>
                <p className="mb-3 fs-md">{t("For any query ask as now")}</p>
                <Link to="#" className="btn btn-primary">
                  {t("Ask Question")}
                </Link>
              </div>
            </div>
            {/* <!-- ========== End Faq heading Section ========== --> */}

            {/* <!-- ========== Start faq list Section ========== --> */}
            <div className="col-lg-9">
              <div className="accordian_area">
                <div className="accordion accordion-flush" id="faq-accordian">
                  {/* <!-- ========== Start faq list map Section ========== --> */}
                  {faqList.map((faqData, index) => {
                    return (
                      <FaqCard key={index} index={index} faqData={faqData} />
                    );
                  })}
                  {/* <!-- ========== End faq list map Section ========== --> */}
                </div>
              </div>
            </div>
            {/* <!-- ========== End faq list Section ========== --> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
