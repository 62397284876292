/* eslint-disable */
import { useState, useEffect } from "react";
import { assetImages } from "Constants/Images";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TestimonialCard from "./TestimonialCard/TestimonialCard";

const Testimonials = () => {
  const [userComment, setuserComment] = useState("");
  const [userRating, setuserRating] = useState(1);

  const starCount = [1, 2, 3, 4, 5];

  const { t } = useTranslation();

  //Array of testimonials.This will replace by API
  const testimonailArray = [
    {
      authorimage: assetImages.TestiAuthor1,
      name: "Larry Nothar",
      position: "Director",
      comment:
        "We are excited about all the upcoming events, where we get to meet the amazing staff and the performance directors",
      rating: 4,
    },
    {
      authorimage: assetImages.TestiAuthor2,
      name: "Alice Johnson",
      position: "Manager",
      comment:
        "We are excited about all the upcoming events, where we get to meet the amazing staff and the performance directors",
      rating: 5,
    },
    {
      authorimage: assetImages.TestiAuthor3,
      name: "John Smith",
      position: "CEO",
      comment:
        "We are excited about all the upcoming events, where we get to meet the amazing staff and the performance directors",
      rating: 3,
    },
    {
      authorimage: assetImages.TestiAuthor4,
      name: "Emily Brown",
      position: "CTO",
      comment:
        "We are excited about all the upcoming events, where we get to meet the amazing staff and the performance directors",
      rating: 2,
    },
    {
      authorimage: assetImages.TestiAuthor1,
      name: "Michael Lee",
      position: "Engineer",
      comment:
        "We are excited about all the upcoming events, where we get to meet the amazing staff and the performance directors",
      rating: 4,
    },
    {
      authorimage: assetImages.TestiAuthor2,
      name: "Sophia Rodriguez",
      position: "Designer",
      comment:
        "We are excited about all the upcoming events, where we get to meet the amazing staff and the performance directors",
      rating: 5,
    },
    {
      authorimage: assetImages.TestiAuthor3,
      name: "David Wilson",
      position: "Analyst",
      comment:
        "We are excited about all the upcoming events, where we get to meet the amazing staff and the performance directors",
      rating: 3,
    },
    {
      authorimage: assetImages.TestiAuthor4,
      name: "Emma Garcia",
      position: "Marketing Manager",
      comment:
        "We are excited about all the upcoming events, where we get to meet the amazing staff and the performance directors",
      rating: 4,
    },
  ];

  //React carousel configuaration
  const sliderResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };

  //useeffect render first element of testimonial array
  useEffect(() => {
    setuserComment(testimonailArray[0].comment);
    setuserRating(testimonailArray[0].rating);
  }, []);

  return (
    <section className="testimonial_sec bg-light-ylw pd-50">
      <div className="container">
        {/* <!-- ========== Start testimnials and rating Section ========== --> */}
        <div className="slider-for">
          <div className="testimonial_info text-center text-center">
            <span className="union_icon mb-5 d-block">
              <img src={assetImages.UnionSvg} alt="" />
            </span>
            <div className="comments">
              <p className="fw-medium mb-4">“{t(userComment)}”</p>
            </div>
            <ul className="list_style_none d-flex justify-content-center">
              {starCount.map((star, index) => {
                return (
                  <li
                    className={
                      star.toString() <= userRating.toString() ? "active" : ""
                    }
                    key={index}
                  >
                    <span className="material-symbols-outlined icon-fill">
                      star
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {/* <!-- ========== End testimnials and rating Section ========== --> */}

        {/* <!-- ========== Start testimnial carousel Section ========== --> */}
        <div className="slider-nav">
          <Carousel infinite responsive={sliderResponsive} arrows={true}>
            {testimonailArray.map((testimonial, index) => {
              return (
                <TestimonialCard
                  key={index}
                  testimonial={testimonial}
                  setuserComment={setuserComment}
                  setuserRating={setuserRating}
                />
              );
            })}
          </Carousel>
        </div>
        {/* <!-- ========== End testimnial carousel Section ========== --> */}
      </div>
    </section>
  );
};

export default Testimonials;
