import React from "react";

const TestimonialCard = ({ testimonial, setuserComment, setuserRating }) => {
  return (
    <div
      className="nav_item"
      onClick={() => {
        setuserComment(testimonial.comment);
        setuserRating(testimonial.rating);
      }}
    >
      <figure>
        <img src={testimonial.authorimage} alt="" />
      </figure>
      <div className="mt-4">
        <h3 className="fs-lg mb-1 fw-bold">{testimonial.name}</h3>
        <h5 className="fs-md">{testimonial.position}</h5>
      </div>
    </div>
  );
};

export default TestimonialCard;
