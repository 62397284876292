import React from "react";

const ExclusiveModuleCard = ({ slideItem }) => {
  return (
    <div className="exclusive_slideitem">
      <div className="exclusive_innrbx bg-white p-4 rounded-16 text-center">
        <div className="exclusive_icon gredient-bg mb-3 rounded-circle d-flex align-items-center justify-content-center">
          <span className="material-symbols-outlined">{slideItem.icon}</span>
        </div>
        <div className="exclusive_text">
          <h3 className="mb-3 fw-bold">
            {slideItem.title} <br /> {slideItem.titleWithBr}
          </h3>
          <p className="fs-md ">{slideItem.description}.</p>
        </div>
      </div>
    </div>
  );
};

export default ExclusiveModuleCard;
